import React from 'react'
import {
  Form,
  required,
  SaveButton,
  TextInput,
  Title,
  useNotify,
  useRedirect,
} from 'react-admin'
import { defaultHttpHeaders } from '../../config/defaultHeaders'

export const RawRewardRuleCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = (data) => {
    notify('Craft Rule Created')
    redirect('edit', '/reward/rules', data.id, data)
  }
  const onError = () => {
    notify('Failed to create Craft Rule', { type: 'error' })
  }

  const handleSubmit = async (data) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/reward/rules`,
      {
        method: 'POST',
        body: data.raw,
        credentials: 'include',
        headers: defaultHttpHeaders,
      }
    )
    if (res.ok) {
      onSuccess()
    } else {
      onError()
    }
  }

  return (
    <>
      <Title title="Create Craft Rule using JSON" />
      <Form onSubmit={handleSubmit}>
        <TextInput
          multiline
          fullWidth={true}
          source="raw"
          label="JSON data"
          validate={required()}
        />
        <SaveButton />
      </Form>
    </>
  )
}
