import { deepmerge } from '@mui/utils'
import { defaultDarkTheme } from 'react-admin'

export const theme = deepmerge(defaultDarkTheme, {
  typography: {
    ...defaultDarkTheme.typography,
    fontSize: 16,
    body1: {
      fontSize: '1rem',
    },
  },
  palette: {
    ...defaultDarkTheme.palette,
    type: 'dark',
    primary: {
      main: '#0ecef2',
      contrastText: '#2c2c2c',
    },
    secondary: {
      light: '#115293',
      main: '#424242',
      contrastText: '#fff',
    },
  },
  overrides: {
    RaSidebar: {
      root: {
        '& .MuiSvgIcon-root': {
          width: '.75em',
          height: '.75em',
        },
      },
      fixed: {
        zIndex: 1200,
      },
    },
    RaMenuItem: {
      link: {
        '&:hover': {
          color: '#2c2c2c',
        },
      },
      active: {
        color: '#0ecef2',
      },
    },
    RaLabeled: {
      value: {
        paddingTop: '.15em',
        marginBottom: '.25em',
        whiteSpace: 'pre-wrap',
      },
    },
    RaFormInput: {
      input: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    RaDatagrid: {
      rowCell: {
        whiteSpace: 'pre-wrap',
      },
    },
  },
  components: {
    ...defaultDarkTheme.components,
    MuiFormControl: { defaultProps: { fullWidth: undefined } },
    MuiTextField: { defaultProps: { fullWidth: undefined } },
    MuiAutocomplete: { defaultProps: { fullWidth: undefined } },
    RaSimpleFormIterator: { defaultProps: { fullWidth: undefined } },
    RaTranslatableInputs: { defaultProps: { fullWidth: undefined } },
  },
})
