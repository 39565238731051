import { gql } from '@apollo/client'

export const FIND_ALL_ACCESSORY_TYPES = gql`
  query findAllAccessoryTypes {
    findAllAccessoryTypes {
      id
      createdAt
      updatedAt
      name
      description
      status
    }
  }
`

export const FIND_ACCESSORY_TYPE = gql`
  query getAccessoryType($id: String!) {
    getAccessoryType(id: $id) {
      id
      createdAt
      updatedAt
      name
      description
      status
      media {
        url
      }
    }
  }
`

export const GET_REWARDS_CONFIG = gql`
  query getRewardsConfiguration {
    getRewardsConfiguration {
      maxEligibleBalance
      mcpMultiplier
      accessoryTypeFactor
      accessoryTypeId
      accessoryTypeMaxTicketReward
    }
  }
`

export const GET_ALL_PENDING_PROCESS = gql`
  query getAllPendingProcesses {
    getAllPendingProcesses {
      id
      status
      createdAt
      updatedAt
      period {
        from
        to
      }
      typeProperties {
        type
      }
    }
  }
`

export const FIND_PENDING_PROCESS = gql`
  query getPendingProcess($id: String!) {
    getPendingProcess(input: { processId: $id }) {
      process {
        id
        status
        createdAt
        updatedAt
        period {
          from
          to
        }
        typeProperties {
          type
        }
      }
    }
  }
`

export const FIND_ACCESSORIES_BALANCES_BY_USER_ID = gql`
  query getAccessoriesBalancesByUserId($id: String!) {
    getAccessoriesBalancesByUserId(input: { userId: $id }) {
      accessoryTypeId
      accessoryTypeName
      balance
    }
  }
`
