import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import Dropzone from 'react-dropzone'
import { defaultHttpHeaders } from '../config/defaultHeaders'
import { ALLOWED_MEDIA_TYPES } from '../config/allowedMediaTypes'
import { withStyles } from '@mui/styles'

const styles = (theme) => ({
  input: {
    display: 'none',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette?.background?.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  image: {
    width: '50%',
    height: '50%',
  },
  dropbox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor: 'pointer',
    marginTop: '20px',
  },
  imagePreview: {
    marginTop: '60px',
  },
  error: {
    background: '#dd0a35',
  },
  success: {
    background: '#8bc24c',
  },
})

const disabledStyles = {
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
  color: 'rgba(255, 255, 255, 0.5)',
  border: '1px solid rgba(255, 255, 255, 0.5)',
  cursor: 'not-allowed',
}

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}
const progressBar = {
  width: 100,
  marginRight: 8,
}
const progressBarWrapper = {
  display: 'flex',
  flexWrap: 'nowrap',
}

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const label = {
  color: 'rgb(255,255,255)',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '12px',
}

const maxSize = 5242880 //5mb

const defaultMediaOpts = {
  endpoint: 'media',
}

class MediaCapture extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: [],
      preview: [],
      error: '',
    }
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  }

  async fetchMediaUrl(image, key, opts) {
    opts = opts || defaultMediaOpts
    const fileExtension = image.type.split('/')[1]
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/${opts.endpoint}`,
      {
        method: 'post',
        body: JSON.stringify({
          entityId: opts.replace
            ? this.props.record?.comicType.id
            : this.props.record
              ? this.props.record?.id
              : this.props.entityId,
          type: this.props.type,
          fileExtension,
          fileType: image.type,
          name: this.props.mediaName ? this.props.mediaName : null,
          pageNumber:
            this.props.type === 'comic_page'
              ? parseInt(image.name.split('.')[0])
              : undefined,
          replace: opts.replace || undefined,
          comicPageId: opts.replace ? this.props.record?.id : undefined,
        }),
        headers: {
          Accept: 'application/json',
          ...defaultHttpHeaders,
        },
        credentials: 'include',
      }
    )

    const data = await response.text()
    const response1 = await fetch(data, {
      method: 'put',
      body: image,
      headers: {
        'Content-Type': image.type,
      },
    })

    if (response1.status && response1.status === 403) {
      this.setState((prevState) => ({
        preview: prevState.preview.map((obj) =>
          obj.key === key
            ? Object.assign(obj, { isUploading: false, uploadError: true })
            : obj
        ),
      }))
    } else {
      this.setState((prevState) => ({
        preview: prevState.preview.map((obj) =>
          obj.key === key
            ? Object.assign(obj, { isUploading: false, isUploaded: true })
            : obj
        ),
      }))
    }
  }

  onDrop = async (files) => {
    let key = 0
    if (this.props.validate) {
      const error = await this.props.validate(files, this.props.record)
      if (error) {
        this.setState({ error })
        return
      }
      this.setState({ error: '' })
    }
    this.setState({ isUploading: true })
    const opts = defaultMediaOpts
    if (this.props.endpoint) {
      opts.endpoint = this.props.endpoint
    }
    if (this.props.replace) {
      opts.replace = this.props.replace
    }
    files.forEach((file) => {
      let reader = new FileReader()
      reader.readAsDataURL(file)
      file.id = key
      key += 1
      reader.onload = (e) => {
        this.setState((prevState) => ({
          images: [...this.state.images, file],
          preview: [
            ...this.state.preview,
            {
              url: e.target.result,
              isUploading: true,
              isUploaded: false,
              uploadError: false,
              key,
            },
          ],
        }))
        this.fetchMediaUrl(file, key, opts)
      }
    })
  }
  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <span style={label}>{this.props.label}</span>
        <div
          className={classes.dropbox}
          style={this.props.disabled ? disabledStyles : {}}
        >
          <Dropzone
            onDrop={this.onDrop}
            accept={Object.fromEntries(
              ALLOWED_MEDIA_TYPES.map((type) => [type, [type]])
            )}
            minSize={0}
            maxSize={maxSize}
            multiple={this.props.isMultiple}
            disabled={this.props.disabled}
          >
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              isDragReject,
              fileRejections,
            }) => {
              const isFileTooLarge =
                fileRejections.length > 0 &&
                fileRejections[0].file.size > maxSize
              return (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive
                    ? 'Drop it to upload'
                    : `Click me or drag ${this.props.label} to upload here.`}
                  {isDragActive && !isDragReject && 'Drop it to upload!'}
                  {isDragReject && 'File type not accepted, sorry!'}
                  {isFileTooLarge && <div>File is too large.</div>}
                </div>
              )
            }}
          </Dropzone>
          {this.state.error.length ? (
            <div className="MuiFormHelperText-root Mui-error">
              {this.state.error}
            </div>
          ) : null}
        </div>
        <aside style={thumbsContainer}>
          {this.state.preview.map((image, key) => (
            <div style={thumb} key={key}>
              <div style={thumbInner}>
                <img src={image.url} style={img} alt="" />
              </div>
            </div>
          ))}
        </aside>
        <div style={progressBarWrapper}>
          {this.state.preview.map((image, key) => (
            <div key={key}>
              {this.state.preview[key].isUploading ? (
                <LinearProgress style={progressBar} />
              ) : null}
            </div>
          ))}
        </div>

        {this.state.preview.map((image, key) => (
          <div key={key}>
            <Snackbar
              open={this.state.preview[key].isUploaded}
              message="File Uploaded successfully"
              autoHideDuration={4000}
              ContentProps={{
                classes: {
                  root: classes.success,
                },
              }}
            />
            <Snackbar
              open={this.state.preview[key].uploadError}
              message="File uploading failed"
              autoHideDuration={4000}
              ContentProps={{
                classes: {
                  root: classes.error,
                },
              }}
            />
          </div>
        ))}
      </Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(MediaCapture)
