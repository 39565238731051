import { gql } from '@apollo/client'

export const CREATE_ACCESSORY_TYPES = gql`
  mutation createAccessoryType($input: CreateAccessoryTypeInput!) {
    createAccessoryType(input: $input) {
      name
      description
    }
  }
`

export const UPDATE_ACCESSORY_TYPE = gql`
  mutation updateAccessoryType($input: UpdateAccessoryTypeInput!) {
    updateAccessoryType(input: $input) {
      description
      status
    }
  }
`

export const DELETE_ACCESSORY_TYPE = gql`
  mutation deleteAccessoryType($id: String!) {
    deleteAccessoryType(id: $id)
  }
`
export const UPDATE_REWARDS_CONFIG = gql`
  mutation updateRewardsConfiguration($input: UpdateRewardsConfigInput!) {
    updateRewardsConfiguration(input: $input) {
      maxEligibleBalance
      mcpMultiplier
      accessoryTypeFactor
      accessoryTypeId
      accessoryTypeMaxTicketReward
    }
  }
`

export const CREATE_PENDING_PROCESS = gql`
  mutation createPendingProcess($input: CreatePendingProcessInput!) {
    createPendingProcess(input: $input)
  }
`

export const DELETE_PENDING_PROCESS = gql`
  mutation deletePendingProcess($id: String!) {
    deletePendingProcess(input: { processId: $id }) {
      process {
        id
      }
    }
  }
`
