import { minLength, maxLength } from 'react-admin'

export const validateMinLength = (minLengthValue) => minLength(minLengthValue)

export const validateMaxLength = (maxLengthValue) => maxLength(maxLengthValue)

export const collectionMcpLevelValidation = (value) => {
  if (value !== null && value < 0) {
    return 'Must be at least 0'
  }
  if (value !== null && value >= 100) {
    return 'Must be less than 100'
  }
  return undefined
}

export const validateFourDigitYear = (value) => {
  const valueAsString = value.toString()
  if (valueAsString.length !== 4 || !Number.isInteger(Number(value))) {
    return 'Must be a 4 digit number'
  }
  return undefined
}

export const validateUUID = (value) => {
  return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
    value
  )
}

export const validateInteger = (value) => {
  return Number.isInteger(Number(value))
}
