import React, { Fragment } from 'react'
import {
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  Form,
  TextInput,
  required,
  useNotify,
  useRedirect,
  SaveButton,
  Title,
} from 'react-admin'
import { defaultPageLimit } from '../../config/defaultPageLimit.js'
import { validateMaxLength, validateMinLength } from '../../utils/validators'
import { defaultHttpHeaders } from '../../config/defaultHeaders.js'

const SendPushNotificationInput = ({ formData, ...rest }) => {
  return (
    <Fragment>
      <TextInput
        source="title"
        label="Title"
        validate={[required(), validateMinLength(3), validateMaxLength(50)]}
      />
      <br />
      <TextInput
        multiline
        source="message"
        label="Message"
        validate={[required(), validateMinLength(3), validateMaxLength(200)]}
      />
    </Fragment>
  )
}

export const SendPushNotification = (_props) => {
  const notify = useNotify()
  const redirect = useRedirect()

  const onSuccess = () => {
    notify('Push notification sent successfully')
    redirect('/notification')
  }
  const onError = () => {
    notify('Failed to sent Push notification', { type: 'error' })
  }

  const handleSubmit = async (data) => {
    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/notification/push`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        credentials: 'include',
        headers: defaultHttpHeaders,
      }
    ).then((res) => res.json())
    if (res === true) {
      onSuccess()
    } else {
      onError()
    }
  }

  return (
    <>
      <Title title="Send Push Notification" />
      <Form onSubmit={handleSubmit}>
        <FormDataConsumer subscription={{ values: true }}>
          {(formDataProps) => <SendPushNotificationInput {...formDataProps} />}
        </FormDataConsumer>

        <SelectInput
          source="type"
          choices={[
            { id: 'series', name: 'Series' },
            { id: 'collectible-types', name: 'Collectible Type' },
            { id: 'comic-types', name: 'Comic Type' },
          ]}
        />

        <FormDataConsumer subscription={{ values: true }}>
          {({ formData, ...rest }) =>
            formData.type && (
              <ReferenceInput
                perPage={defaultPageLimit}
                source="elementId"
                reference={formData.type}
                sort={{ field: 'name', order: 'ASC' }}
                enableGetChoices={({ searchQuery }) =>
                  searchQuery ? searchQuery.length >= 2 : false
                }
                allowEmpty
                {...rest}
              >
                <AutocompleteInput
                  filterToQuery={(searchText) => ({ searchQuery: searchText })}
                  noOptionsText
                  shouldRenderSuggestions={(value) => value.length >= 2}
                  resettable
                />
              </ReferenceInput>
            )
          }
        </FormDataConsumer>
        <SaveButton />
      </Form>
    </>
  )
}
