import { GET_ALL_PENDING_PROCESS, FIND_PENDING_PROCESS } from '../queries'
import { GET_ONE, DELETE, GET_LIST, CREATE } from 'react-admin'
import { CREATE_PENDING_PROCESS, DELETE_PENDING_PROCESS } from '../mutations'

const getRewardPendingProcessList = {
  query: GET_ALL_PENDING_PROCESS,
  parseResponse: ({ data }) => {
    const { getAllPendingProcesses } = data
    return {
      data: getAllPendingProcesses,
      total: getAllPendingProcesses.length,
    }
  },
}

const createPendingProcess = (params) => {
  return {
    query: CREATE_PENDING_PROCESS,
    variables: {
      input: {
        ...params.data,
      },
    },
    parseResponse: ({ data }) => ({
      data: { ...params.data, id: data.createPendingProcess.id },
    }),
  }
}

const getPendingProcess = (id) => {
  return {
    query: FIND_PENDING_PROCESS,
    variables: { id },
    parseResponse: ({ data }) => {
      const { getPendingProcess } = data
      return {
        data: {
          ...getPendingProcess.process,
        },
      }
    },
  }
}

const deletePendingProcess = (id) => {
  return {
    query: DELETE_PENDING_PROCESS,
    variables: {
      id,
    },
    parseResponse: ({ data }) => ({
      data: { id: data.deletePendingProcess.id },
    }),
  }
}

export const rewardPendingProcess = (fetchType, params) => {
  switch (fetchType) {
    case CREATE:
      return createPendingProcess(params)
    case GET_LIST:
      return getRewardPendingProcessList
    case GET_ONE:
      return getPendingProcess(params.id)
    case DELETE:
      return deletePendingProcess(params.id)
    default:
      throw new Error(`Unknown fetchType ${fetchType}`)
  }
}
