import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/lab/Autocomplete'
import fetch from 'cross-fetch'
import debounce from 'lodash/debounce'
import React, { useState } from 'react'
import { defaultHttpHeaders } from '../config/defaultHeaders'
import { useListContext } from 'react-admin'

const { 'content-type': remove, ...headers } = defaultHttpHeaders
const SearchInput = (props) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { resource, setFilters, refetch } = useListContext()

  // Function to fetch suggested tags
  const fetchSuggestedTags = async (inputValue) => {
    if (inputValue.length > 0 && inputValue.search('#') === -1) {
      setLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/admin/${resource}/suggest/${inputValue.split(' ').join('')}`,
          {
            headers,
            credentials: 'include',
          }
        )
        const data = await response.json()
        if (Array.isArray(data)) {
          const hashtags = data.map((hashtag) => `#${hashtag}`)
          setOptions(hashtags)
        }
      } catch (error) {
        console.error('Error fetching suggested tags:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  // Debounced handler for input changes
  const handleInput = debounce(fetchSuggestedTags, 250)

  // Handle selection change
  const handleChange = (_, value) => {
    setFilters({ [props.source]: value })
    refetch()
  }

  return (
    <Autocomplete
      id="searchQuery"
      style={{ width: 300 }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      autoSelect
      options={options}
      loading={loading}
      onInputChange={(event, newValue) => {
        handleInput(newValue)
      }}
      onChange={handleChange}
      name="searchQuery"
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search"
          variant="outlined"
          slotProps={{ input: params.InputProps }}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}

export default SearchInput
