import buildGraphQLProvider, { buildQuery } from 'ra-data-graphql-simple'
import { GET_LIST, CREATE, GET_ONE, DELETE } from 'react-admin'
import {
  accessoryBalanceHandle,
  accessoryTypeHandle,
} from './modules/accessoryType'
import { rewardPendingProcess } from './modules/rewardPendingProcess'

const getGqlResource = (resource) => {
  switch (resource) {
    case 'accessory-type':
      return 'AccessoryType'
    default:
      throw new Error(`Unknown resource ${resource}`)
  }
}

const appCustomBuildQuery =
  (introspection) => (fetchType, resource, params) => {
    switch (resource) {
      case 'accessory-balance':
        return accessoryBalanceHandle(fetchType, params)
      case 'accessory-type':
        return accessoryTypeHandle(fetchType, params)
      case 'rewards/pending-process':
        return rewardPendingProcess(fetchType, params)
      default:
        return buildQuery(introspection)(
          fetchType,
          getGqlResource(resource),
          params
        )
    }
  }

const graphqlProvider = async () => {
  return await buildGraphQLProvider({
    buildQuery: appCustomBuildQuery,
    clientOptions: {
      uri: `${process.env.REACT_APP_API_DIGITAL_ASSETS_URL}`,
    },
    introspection: {
      operationNames: {
        [GET_LIST]: () => `findAllAccessoryTypes`,
        [CREATE]: () => `createAccessoryType`,
        [GET_ONE]: () => `getAccessoryType`,
        [DELETE]: () => `deleteAccessoryType`,
      },
    },
  })
}

export default graphqlProvider
