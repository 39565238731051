const isCreatorPlatformEnabled = () => {
  let enabled = false
  try {
    const featureFlagsJson = localStorage.getItem('featureFlags')
    if (featureFlagsJson) {
      const featureFlags = JSON.parse(featureFlagsJson)
      const creatorPlatform = featureFlags.find(
        (flag) => flag.name === 'creatorPlatformApiEnabled'
      )

      if (creatorPlatform && creatorPlatform.enabled) {
        enabled = true
      }
    }
  } catch {
    console.log('Error while parsing creatorPlatform feature flag')
  }

  return enabled
}

export default isCreatorPlatformEnabled
