import AllInboxIcon from '@mui/icons-material/AllInbox'
import AndroidIcon from '@mui/icons-material/Android'
import Reedem from '@mui/icons-material/Redeem'
import {
  default as AccessoriesIcon,
  default as CollectibleTypeIcon,
  default as CollectionSetIcon,
} from '@mui/icons-material/Apps'
import CollectibleIcon from '@mui/icons-material/ArtTrack'
import {
  default as AuctionBidIcon,
  default as PayoutIcon,
} from '@mui/icons-material/AttachMoney'
import ArtistIcon from '@mui/icons-material/Brush'
import {
  default as ComicPagesIcon,
  default as PromotionBannerIcon,
} from '@mui/icons-material/BurstMode'
import MarketingWindowIcon from '@mui/icons-material/ViewCarousel'
import LicensorIcon from '@mui/icons-material/Business'
import CommentIcon from '@mui/icons-material/Comment'
import WriterIcon from '@mui/icons-material/Create'
import CharacterIcon from '@mui/icons-material/DirectionsRun'
import ComicSeriesIcon from '@mui/icons-material/DynamicFeed'
import CustomizationCategoryIcon from '@mui/icons-material/Edit'
import StoreAuctionBidIcon from '@mui/icons-material/EmojiPeople'
import StoreAuctionIcon from '@mui/icons-material/Gavel'
import AccountMergeIcon from '@mui/icons-material/Group'
import ComicCoverIcon from '@mui/icons-material/Image'
import HashtagIcon from '@mui/icons-material/Label'
import BrandIcon from '@mui/icons-material/Layers'
import ComicTypeIcon from '@mui/icons-material/LibraryBooks'
import FeedIcon from '@mui/icons-material/ListAlt'
import MoneyIcon from '@mui/icons-material/MonetizationOn'
import PromoIcon from '@mui/icons-material/LocalOffer'
import ComicIcon from '@mui/icons-material/MenuBook'
import PayoutMethodIcon from '@mui/icons-material/MoneyOff'
import NotificationsIcon from '@mui/icons-material/NotificationsActive'
import PaletteIcon from '@mui/icons-material/Palette'
import TransactionIcon from '@mui/icons-material/Payment'
import UserIcon from '@mui/icons-material/People'
import ShowroomBackgroundIcon from '@mui/icons-material/PermMedia'
import MediaIcon from '@mui/icons-material/PhotoLibrary'
import UserGemBalanceLogIcon from '@mui/icons-material/PlaylistAddCheck'
import PublisherIcon from '@mui/icons-material/Print'
import MarketListingIcon from '@mui/icons-material/ShoppingBasket'
import ShowroomIcon from '@mui/icons-material/ThreeDRotation'
import SeriesIcon from '@mui/icons-material/Tv'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import AccountDeletionIcon from '@mui/icons-material/Remove'

import {
  default as LoginBackgroundIcon,
  default as ShowroomBackgroundTypeIcon,
} from '@mui/icons-material/Wallpaper'
import Spinner from '@mui/material/CircularProgress' // Assuming Spinner is a CircularProgress component from Material-UI
import { MultiLevelMenu } from '@react-admin/ra-navigation'
import { IfCanAccess, useCanAccess } from '@react-admin/ra-rbac'
import * as React from 'react'
import isWaitlistV2Enabled from './waitlistV2'
import { SplitTreatments } from '@splitsoftware/splitio-react'
import { FeatureFlags } from '../config/featureFlags'

const { Item: MenuItem } = MultiLevelMenu

export const Menu = () => {
  const { canAccess: canAccessMarketListings } = useCanAccess({
    action: 'list',
    resource: 'market-listings',
  })

  const { canAccess: canAccessStoreAuctions } = useCanAccess({
    action: 'list',
    resource: 'store-auctions',
  })
  const { canAccess: canAccessShowrooms } = useCanAccess({
    action: 'list',
    resource: 'showrooms',
  })
  const { canAccess: canAccessShowroomBackground } = useCanAccess({
    action: 'list',
    resource: 'showroom-background',
  })
  const { canAccess: canAccessLicensors } = useCanAccess({
    action: 'list',
    resource: 'licensors',
  })
  const { canAccess: canAccessPublishers } = useCanAccess({
    action: 'list',
    resource: 'publishers',
  })
  const { canAccess: canAccessComics } = useCanAccess({
    action: 'list',
    resource: 'comics',
  })

  const { canAccess: canAccessPayoutProfiles } = useCanAccess({
    action: 'list',
    resource: 'payout-profiles',
  })

  const { canAccess: canAccessPayoutMethods } = useCanAccess({
    action: 'list',
    resource: 'payout-methods',
  })

  const { canAccess: canAccessPayoutTransactions } = useCanAccess({
    action: 'list',
    resource: 'payments/payouts',
  })

  const { canAccess: canAccessPayoutNiumRfi } = useCanAccess({
    action: 'list',
    resource: 'payments/payout-nium-rfi',
  })

  const defaultPayoutPage = canAccessPayoutProfiles
    ? 'payout-profiles'
    : canAccessPayoutMethods
      ? 'payout-methods'
      : canAccessPayoutTransactions
        ? 'payments/payouts'
        : undefined

  return (
    <MultiLevelMenu>
      <IfCanAccess action="list" resource="users">
        <MenuItem name="users" to="/" label="Users" icon={<UserIcon />}>
          <IfCanAccess action="*" resource="bulk">
            <MenuItem
              name="bulk-transfer"
              to="/bulk/create"
              label="Bulk Transfer"
              icon={<UserIcon />}
            />
          </IfCanAccess>
          <MenuItem
            name="kyc"
            to="/kyc"
            label="Kyc"
            icon={<VerifiedUserIcon />}
          />
          <MenuItem
            name="user-gem-balance-log"
            to="/user-gem-balance-log"
            label="GEM balance log"
            icon={<UserGemBalanceLogIcon />}
          />
          <MenuItem
            name="account-deletion"
            to="/account-deletion"
            label="Account deletion"
            icon={<AccountDeletionIcon />}
          />
          <MenuItem
            name="account-merge"
            to="/account-merge"
            label="Account merge"
            icon={<AccountMergeIcon />}
          />
        </MenuItem>
      </IfCanAccess>

      {defaultPayoutPage && (
        <MenuItem
          name="payoutProfiles"
          to={defaultPayoutPage}
          label="Payout"
          icon={<PayoutIcon />}
        >
          {canAccessPayoutProfiles && (
            <MenuItem
              name="payoutProfiles"
              to="/payout-profiles"
              label="Payout Profiles"
              icon={<PayoutIcon />}
            />
          )}
          {canAccessPayoutMethods && (
            <MenuItem
              name="payoutMethods"
              to="/payout-methods"
              label="Payout Methods"
              icon={<PayoutMethodIcon />}
            />
          )}
          {canAccessPayoutTransactions && (
            <MenuItem
              name="payoutTransactions"
              to="/payments/payouts"
              label="Payout Transactions"
              icon={<PayoutIcon />}
            />
          )}
          {canAccessPayoutNiumRfi && (
            <MenuItem
              name="payoutRfi"
              to="/payments/payout-nium-rfi"
              label="Payout Nium Rfi"
              icon={<PayoutIcon />}
            />
          )}
        </MenuItem>
      )}

      {(canAccessLicensors && (
        <MenuItem
          name="licensors"
          to="/licensors"
          label="Digital Collectibles"
          icon={<CollectibleIcon />}
        >
          <MenuItem
            name="licensors"
            to="/licensors"
            label="Licensors"
            icon={<LicensorIcon />}
          />
          <MenuItem
            name="brands"
            to="/brands"
            label="Brands"
            icon={<BrandIcon />}
          />
          <MenuItem
            name="series"
            to="/series"
            label="Series"
            icon={<SeriesIcon />}
          />
          <MenuItem
            name="collectibleTypes"
            to="/collectible-types"
            label="Collectible Types"
            icon={<CollectibleTypeIcon />}
          />
          <IfCanAccess action="list" resource="collectibles">
            <MenuItem
              name="collectibles"
              to="/collectibles"
              label="Collectibles"
              icon={<CollectibleIcon />}
            />
          </IfCanAccess>
          <MenuItem
            name="collectibleTypeConvertMapping"
            to="/collectible-type-convert-mapping"
            label="Redemption Rewards"
            icon={<CollectibleTypeIcon />}
          />
        </MenuItem>
      )) || (
        <MenuItem
          name="collectibles"
          to="/collectibles"
          label="Collectibles"
          icon={<CollectibleIcon />}
        />
      )}

      <IfCanAccess action="list" resource="publishers">
        <MenuItem
          name="publishers"
          to="/publishers"
          label="Digital Comics"
          icon={<ComicIcon />}
        >
          <MenuItem
            name="publishers"
            to="/publishers"
            label="Publishers"
            icon={<PublisherIcon />}
          />
          <MenuItem
            name="comic-series"
            to="/comic-series"
            label="Comic Series"
            icon={<ComicSeriesIcon />}
          />
          <MenuItem
            name="comic-types"
            to="/comic-types"
            label="Comic Types"
            icon={<ComicTypeIcon />}
          />
          <MenuItem
            name="comics"
            to="/comics"
            label="Comics"
            icon={<ComicIcon />}
          />
          <MenuItem
            name="comic-covers"
            to="/comic-covers"
            label="Comic Covers"
            icon={<ComicCoverIcon />}
          />
          <MenuItem
            name="comic-pages"
            to="/comic-pages"
            label="Comic Pages"
            icon={<ComicPagesIcon />}
          />
          <MenuItem
            name="artists"
            to="/artists"
            label="Artists"
            icon={<ArtistIcon />}
          />
          <MenuItem
            name="writers"
            to="/writers"
            label="Writers"
            icon={<WriterIcon />}
          />
          <MenuItem
            name="characters"
            to="/characters"
            label="Characters"
            icon={<CharacterIcon />}
          />
          <MenuItem
            name="custom-groups"
            to="/custom-groups"
            label="Custom groups"
            icon={<AllInboxIcon />}
          />
          <MenuItem
            name="promotional-reservation"
            to="/promotional-reservation"
            label="Promotion Reserve"
            icon={<Reedem />}
          />
          <MenuItem
            name="marketing-window"
            to="/marketing-window"
            label="Marketing Windows"
            icon={<MarketingWindowIcon />}
          />
          <SplitTreatments names={[FeatureFlags.ComicRefundPayments]}>
            {({ treatments, isReady }) => {
              // Passes down a TreatmentsWithConfig object and SplitContext properties like the boolean `isReady` flag.
              return isReady ? (
                treatments[FeatureFlags.ComicRefundPayments].treatment ===
                'on' ? (
                  <IfCanAccess action="list" resource="refund-payments">
                    <MenuItem
                      name="refund-payments"
                      to="/refund-payments"
                      label="Comic Refunds"
                      icon={<MoneyIcon />}
                    />
                  </IfCanAccess>
                ) : null
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '15px 0',
                  }}
                >
                  <Spinner size={20} />
                </div>
              ) // Render a spinner if the SDK is not ready yet. You can do what you want with this boolean.
            }}
          </SplitTreatments>
        </MenuItem>
      </IfCanAccess>
      {canAccessComics && !canAccessPublishers && (
        <MenuItem
          name="comics"
          to="/comics"
          label="Comics"
          icon={<ComicIcon />}
        />
      )}

      <SplitTreatments names={[FeatureFlags.EvolvablesAdmin]}>
        {({ treatments, isReady }) => {
          // Passes down a TreatmentsWithConfig object and SplitContext properties like the boolean `isReady` flag.
          return isReady ? (
            treatments[FeatureFlags.EvolvablesAdmin].treatment === 'on' ? (
              <IfCanAccess action="list" resource="collection-sets">
                <MenuItem
                  name="publishers"
                  to="/collection-sets"
                  label="Collection Sets"
                  icon={<CollectionSetIcon />}
                />
              </IfCanAccess>
            ) : null
          ) : (
            <Spinner />
          ) // Render a spinner if the SDK is not ready yet. You can do what you want with this boolean.
        }}
      </SplitTreatments>

      <IfCanAccess action="list" resource="accessory-type">
        <MenuItem
          name="accessory-type"
          to="/accessory-type"
          label="Accessories"
          icon={<AccessoriesIcon />}
        >
          <MenuItem
            name="accessory-type"
            to="/accessory-type"
            label="Accessory Type"
            icon={<AccessoriesIcon />}
          />
          <MenuItem
            name="accessory"
            to="/accessory"
            label="Accessory"
            icon={<ShowroomBackgroundIcon />}
          />

          <MenuItem
            name="accessory-balances"
            to="/accessory-balance"
            label="Accessory Balances"
            icon={<CollectibleIcon />}
          />
        </MenuItem>
      </IfCanAccess>

      {(canAccessShowrooms && (
        <MenuItem
          name="showrooms"
          to="/showrooms"
          label="Showrooms"
          icon={<ShowroomIcon />}
        >
          <MenuItem
            name="showrooms"
            to="/showrooms"
            label="Showroom"
            icon={<ShowroomIcon />}
          />
          <MenuItem
            name="showroom-background-type"
            to="/showroom-background-type"
            label="Showroom BG Type"
            icon={<ShowroomBackgroundTypeIcon />}
          />
          <MenuItem
            name="showroom-background"
            to="/showroom-background"
            label="Showroom BG"
            icon={<ShowroomBackgroundIcon />}
          />
        </MenuItem>
      )) ||
        (canAccessShowroomBackground && (
          <MenuItem
            name="showroom-background-type"
            to="/showroom-background-type"
            label="Showroom BG Type"
            icon={<ShowroomBackgroundTypeIcon />}
          >
            <MenuItem
              name="showroom-background-type"
              to="/showroom-background-type"
              label="Showroom BG Type"
              icon={<ShowroomBackgroundTypeIcon />}
            />
            <MenuItem
              name="showroom-background"
              to="/showroom-background"
              label="Showroom BG"
              icon={<ShowroomBackgroundIcon />}
            />
          </MenuItem>
        ))}

      {canAccessLicensors && canAccessPublishers && isWaitlistV2Enabled() && (
        <MenuItem
          name="waitlist-segments"
          to="/waitlist-segments"
          label="Waitlist Segments"
          icon={<ComicCoverIcon />}
        />
      )}

      {canAccessStoreAuctions && (
        <MenuItem
          name="storeAuction"
          to="/store-auctions"
          label="Store auctions"
          icon={<StoreAuctionIcon />}
        >
          <MenuItem
            name="storeAuction"
            to="/store-auctions"
            label="Store auctions"
            icon={<StoreAuctionIcon />}
          />
          <MenuItem
            name="storeAuctionBid"
            to="/store-auction-bids"
            label="Store Auction Bids"
            icon={<StoreAuctionBidIcon />}
          />
        </MenuItem>
      )}
      {(canAccessMarketListings && (
        <MenuItem
          name="marketListing"
          to="/market-listings"
          label="Market"
          icon={<MarketListingIcon />}
        >
          <MenuItem
            name="marketListing"
            to="/market-listings"
            label="Market Listing"
            icon={<MarketListingIcon />}
          />
          <MenuItem
            name="auctions"
            to="/auctions"
            label="Auction Bids"
            icon={<AuctionBidIcon />}
          />
        </MenuItem>
      )) || (
        <MenuItem
          name="auctions"
          to="/auctions"
          label="Auction Bids"
          icon={<AuctionBidIcon />}
        />
      )}

      <IfCanAccess action="list" resource="customization-category">
        <MenuItem
          name="customizationCategory"
          to="/customization-category"
          label="Customization Category"
          icon={<CustomizationCategoryIcon />}
        />
      </IfCanAccess>

      <IfCanAccess action="list" resource="login-background">
        <MenuItem
          name="login-background"
          to="/login-background"
          label="Login Background"
          icon={<LoginBackgroundIcon />}
        />
      </IfCanAccess>
      <MenuItem name="media" to="/media" label="Media" icon={<MediaIcon />} />

      <IfCanAccess action="list" resource="feed">
        <MenuItem name="feed" to="/feed" label="Feed" icon={<FeedIcon />} />
      </IfCanAccess>

      <IfCanAccess action="list" resource="transactions">
        <MenuItem
          name="transactions"
          to="/transactions"
          label="Transactions"
          icon={<TransactionIcon />}
        />
      </IfCanAccess>
      <IfCanAccess action="list" resource="master-collector/user-summary">
        <MenuItem
          name="master-collector/user-summary"
          to="/master-collector/user-summary"
          label="MCP"
          icon={<TransactionIcon />}
        >
          <MenuItem
            name="master-collector/user-summary"
            to="/master-collector/user-summary"
            label="User summary"
            icon={<TransactionIcon />}
          />
          <IfCanAccess action="list" resource="master-collector/transaction">
            <MenuItem
              name="master-collector/transaction"
              to="/master-collector/transaction"
              label="Transactions"
              icon={<TransactionIcon />}
            />
          </IfCanAccess>
          <IfCanAccess
            action="list"
            resource="master-collector/activity-point-value"
          >
            <MenuItem
              name="master-collector/activity-point-value"
              to="/master-collector/activity-point-value"
              label="Activity point value"
              icon={<TransactionIcon />}
            />
          </IfCanAccess>
          <IfCanAccess action="list" resource="master-collector/levels">
            <MenuItem
              name="master-collector/levels"
              to="/master-collector/levels"
              label="Levels"
              icon={<TransactionIcon />}
            />
          </IfCanAccess>
          <IfCanAccess action="list" resource="master-collector/ranks">
            <MenuItem
              name="master-collector/ranks"
              to="/master-collector/ranks"
              label="Ranks"
              icon={<TransactionIcon />}
            />
          </IfCanAccess>
        </MenuItem>
      </IfCanAccess>
      <MenuItem
        name="rewards"
        to="/rewards/pending-process"
        label="Rewards"
        icon={<TransactionIcon />}
      >
        <MenuItem
          name="rewards/pending-process"
          to="/rewards/pending-process"
          label="Pending Process"
          icon={<TransactionIcon />}
        />
      </MenuItem>

      <IfCanAccess action="list" resource="reward/rules">
        <MenuItem
          name="reward/rules"
          to="/reward/rules"
          label="Craft Rules"
          icon={<TransactionIcon />}
        >
          <IfCanAccess action="create" resource="reward/rules">
            <MenuItem
              name="reward/rules/create/raw"
              to="/reward/rules/create/raw"
              label="Create using JSON"
              icon={<TransactionIcon />}
            />
          </IfCanAccess>
        </MenuItem>
      </IfCanAccess>
      <IfCanAccess action="list" resource="theme">
        <MenuItem
          name="theme"
          to="/theme"
          label="Themes"
          icon={<PaletteIcon />}
        />
      </IfCanAccess>
      <IfCanAccess action="list" resource="comments">
        <MenuItem
          name="comments"
          to="/comments"
          label="Comments"
          icon={<CommentIcon />}
        />
      </IfCanAccess>
      <IfCanAccess action="list" resource="hashtags">
        <MenuItem
          name="hashtags"
          to="/hashtags"
          label="Hashtags"
          icon={<HashtagIcon />}
        />
      </IfCanAccess>
      <IfCanAccess action="list" resource="promos">
        <MenuItem
          name="promos"
          to="/promos"
          label="Promos"
          icon={<PromoIcon />}
        />
      </IfCanAccess>
      <IfCanAccess action="list" resource="promotionBanners">
        <MenuItem
          name="promotionBanners"
          to="/promotion-banners"
          label="Promotion Banners"
          icon={<PromotionBannerIcon />}
        />
      </IfCanAccess>
      <MenuItem
        name="notification"
        to="/notification"
        label="Notifications"
        icon={<NotificationsIcon />}
      >
        <IfCanAccess action="create" resource="notification/push">
          <MenuItem
            name="notification/push/create"
            to="/notification/push/create"
            label="Send Push Notification"
            icon={<NotificationsIcon />}
          />
        </IfCanAccess>
      </MenuItem>
      <IfCanAccess action="*" resource="/kasada/events">
        <MenuItem
          name="kasada"
          to="/kasada/events"
          label="Kasada"
          icon={<AndroidIcon />}
        />
      </IfCanAccess>

      <SplitTreatments names={[FeatureFlags.RecipesAdmin]}>
        {({ treatments, isReady }) => {
          return (
            isReady &&
            (treatments[FeatureFlags.RecipesAdmin].treatment === 'on' ? (
              <IfCanAccess action="list" resource="recipes">
                <MenuItem
                  name="recipes"
                  to="/recipes"
                  label="Recipes"
                  icon={<PaletteIcon />}
                />
              </IfCanAccess>
            ) : null)
          )
        }}
      </SplitTreatments>
    </MultiLevelMenu>
  )
}
