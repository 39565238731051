import { defaultHttpHeaders } from '../config/defaultHeaders'
import { roles } from '../config/roles'
import otpService from './otpService'

const getIdentity = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/auth/me`,
    {
      headers: defaultHttpHeaders,
      credentials: 'include',
    }
  )
  if (response.ok) {
    const me = await response.json()
    localStorage.setItem('role', me.role)
    return Promise.resolve(me)
  }
  localStorage.removeItem('role')
  return Promise.reject('failed to get identity')
}

const attemptLogin = async (body, username) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
    {
      method: 'POST',
      body,
      headers: defaultHttpHeaders,
      credentials: 'include',
    }
  )
  const res = await response.json()

  if (res !== true) {
    window.dispatchEvent(new CustomEvent('wrongCredentials'))
    return
  }

  localStorage.setItem('username', username)
  await getIdentity()
  window.location.reload()
}

const authProvider = {
  // called when the user attempts to log in
  login: async (params) => {
    const { username, password } = params

    const resFeatureFlag = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/admin/feature-flag?range=[0,200]`,
      {
        headers: defaultHttpHeaders,
      }
    )
    const featureFlags = await resFeatureFlag.json()
    localStorage.setItem('featureFlags', JSON.stringify(featureFlags))
    const requiresOtp = featureFlags.find(
      (flag) => flag.name === 'twoFactorAuthentication'
    )

    if (!requiresOtp.enabled) {
      const body = JSON.stringify({ email: username, password })
      await attemptLogin(body, username)
    } else {
      await otpService(
        username,
        async (totp) => {
          const body = JSON.stringify({ email: username, password, totp })
          await attemptLogin(body, username)
        },
        { login: true }
      )
      return Promise.reject('Enter OTP')
    }
  },

  // called when the user clicks on the logout button
  logout: async () => {
    localStorage.removeItem('username')
    localStorage.removeItem('role')
    localStorage.removeItem('featureFlags')
    window.location.href = '/#/login'
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`, {
      method: 'GET',
      credentials: 'include',
      headers: defaultHttpHeaders,
    })
    return Promise.resolve()
  },

  // called when the API returns an error
  checkError: (params) => {
    const { status, message } = params
    if (status === 401 || status === 403) {
      if (status === 401 && message === '2FA needed to complete this action') {
        return Promise.resolve()
      }
      localStorage.removeItem('username')
      return Promise.reject()
    }
    return Promise.resolve()
  },

  // called when the user navigates to a new location
  checkAuth: () => {
    return localStorage.getItem('username')
      ? Promise.resolve()
      : Promise.reject()
  },

  getIdentity,

  getPermissions: () => {
    const role = localStorage.getItem('role')
    const permissions = roles[role] || []
    return Promise.resolve(permissions)
  },

  getRoles: () => {
    return Promise.resolve(roles)
  },
}

export default authProvider
