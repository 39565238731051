const articles = ['a', 'an', 'the', 'of', 'at']

export const labelFromSource = (source) => {
  return source
    .split(/(?=[A-Z])/g)
    .map((i) => {
      return articles.includes(i.toLowerCase())
        ? i.toLowerCase()
        : i.charAt(0).toUpperCase() + i.substr(1)
    })
    .join(' ')
}
