import { defaultHttpHeaders } from '../config/defaultHeaders'

const requestTotp = async (email) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/auth/totp/send`,
    {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: defaultHttpHeaders,
    }
  )

  if (response.status > 200) {
    const message = (await response.json()).message[0]
    return Promise.reject(message)
  }

  return await response.text()
}

const verifyTotp = async (totp) => {
  return await fetch(`${process.env.REACT_APP_API_BASE_URL}/auth/totp/verify`, {
    method: 'POST',
    body: JSON.stringify({ token: totp }),
    headers: defaultHttpHeaders,
    credentials: 'include',
  }).then(async (response) => {
    if (!response.ok) {
      const data = await response.json()
      throw new Error(data.message)
    }
    return true
  })
}

const otpService = async (email, callback, options) => {
  options = options || {}

  // for non login endpoints, show the modal without calling OTP send API, since the backend guard sends the OTP by default
  if (!email || (await requestTotp(email)) === 'ok') {
    window.dispatchEvent(new CustomEvent('displayOtpModal'))
  }

  const cleanup = () => {
    window.removeEventListener('otpEntered', otpEntered)
  }
  const otpEntered = async (e) => {
    const totp = e.detail ? e.detail.token : null
    if (totp) {
      if (options.login) {
        return callback(totp)
      }
      await verifyTotp(totp)
        .then(() => {
          window.dispatchEvent(new CustomEvent('closeOtpModal'))
          callback()
        })
        .catch((err) => {
          window.dispatchEvent(new CustomEvent('wrongCredentials'))
          window.addEventListener('otpEntered', otpEntered, { once: true })
        })
    }
  }

  window.addEventListener('otpEntered', otpEntered, { once: true })
  window.addEventListener('closeOtpModal', cleanup, { once: true })
}

export default otpService
