import { Button } from '@mui/material'
import {
  useRecordContext,
  useShowContext,
  Confirm,
  useNotify,
  useDataProvider,
} from 'react-admin'
import React, { useState } from 'react'
import DoneIcon from '@mui/icons-material/Done'
import CrossIcon from '@mui/icons-material/Close'

export const ApproveButton = ({ small = false }) => {
  const notify = useNotify()
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { resource, refetch } = useShowContext()
  const dataProvider = useDataProvider()
  const record = useRecordContext()

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await dataProvider.approveRefund(resource, {
        id: record?.id,
      })
      refetch()
      setIsLoading(false)
      setIsOpen(false)
    } catch (error) {
      if (error.message instanceof Array) {
        error.message = error.message.join(',')
      }

      notify(error.message, {
        type: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <>
      <Confirm
        isOpen={isOpen}
        loading={isLoading}
        title="Approve Refund"
        content="Are you sure you want to approve this refund?"
        onConfirm={handleConfirm}
        onClose={() => setIsOpen(false)}
        confirm="Approve"
      />
      <Button
        color="primary"
        size={small ? 'small' : 'medium'}
        disabled={!record}
        onClick={() => setIsOpen(true)}
        startIcon={<DoneIcon />}
      >
        {small ? 'Approve' : 'Approve Refund'}
      </Button>
    </>
  )
}

export const RejectButton = ({ small = false }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { resource, refetch } = useShowContext()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const record = useRecordContext()

  const handleConfirm = async () => {
    try {
      setIsLoading(true)
      await dataProvider.rejectRefund(resource, {
        id: record?.id,
      })
      refetch()
      setIsLoading(false)
      setIsOpen(false)
    } catch (error) {
      if (error.message instanceof Array) {
        error.message = error.message.join(',')
      }

      notify(error.message, {
        type: 'error',
      })
      setIsLoading(false)
    }
  }

  return (
    <>
      <Confirm
        isOpen={isOpen}
        loading={isLoading}
        title="Reject Refund"
        content="Are you sure you want to reject this refund?"
        onConfirm={handleConfirm}
        onClose={() => setIsOpen(false)}
        confirm="Reject"
      />
      <Button
        color="error"
        size={small ? 'small' : 'medium'}
        disabled={!record}
        variant="text"
        onClick={() => setIsOpen(true)}
        style={{ color: 'red' }}
        startIcon={<CrossIcon />}
      >
        {small ? 'Reject' : 'Reject Refund'}
      </Button>
    </>
  )
}
