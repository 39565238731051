import React from 'react'
import {
  Show,
  TextField,
  FunctionField,
  SimpleShowLayout,
  SingleFieldList,
  ReferenceField,
  ChipField,
  useRecordContext,
  TopToolbar,
  BooleanField,
  ArrayField,
} from 'react-admin'
import { ApproveButton, RejectButton } from './utils/refundActions'
import { UserLink } from '../../utils/userLink'
import DateField from '../../utils/dateField'
import { Grid2, Typography } from '@mui/material'

const ComicViewTitle = () => {
  const record = useRecordContext()
  return <span>Refund {`"${record?.id}"`}</span>
}

const ComicActions = () => {
  const record = useRecordContext()
  return (
    <TopToolbar>
      {record?.status === 'pending_approval' && (
        <>
          <ApproveButton />
          <RejectButton />
        </>
      )}
    </TopToolbar>
  )
}

export const ComicRefundView = (props) => {
  return (
    <Show actions={<ComicActions />} title={<ComicViewTitle />} {...props}>
      <Grid2 container spacing={2}>
        <Grid2 item xs={4}>
          <SimpleShowLayout>
            <Typography variant="h5" style={{ marginBottom: '10px' }}>
              Refund Info
            </Typography>
            <UserLink label="User" source="user" type="chip" />
            <FunctionField
              label="User Name"
              render={(record) =>
                `${record?.user.firstName} ${record?.user.lastName}`
              }
            />
            ;
            <TextField source="user.email" label="User Email" />
            <TextField source="cartId" label="Cart Id" />
            <TextField source="paymentId" label="Payment Id" />
            <TextField source="amountUsd" label="Refund Amount" />
            <ChipField source="status" label="Status" />
            <TextField source="reason" label="Reason" />
            <DateField dateSource="createdAt" />
            <DateField dateSource="updatedAt" />
          </SimpleShowLayout>
        </Grid2>
        <Grid2 item xs={4}>
          <SimpleShowLayout>
            <Typography variant="h5" style={{ marginBottom: '10px' }}>
              Failed Items
            </Typography>
            <BooleanField
              source="failedCartPurchaseProcess.data.partialRefund"
              label="Is Partial Refund"
            />
            <ArrayField
              source="failedCartPurchaseProcess.data.items"
              label="Items"
            >
              <SingleFieldList>
                <SimpleShowLayout>
                  <BooleanField source="isECL" label="Is ECL" />
                  <BooleanField source="isEclUpgrade" label="Is ECL Upgrade" />
                  <ReferenceField
                    label="Comic Type"
                    source="entityId"
                    reference="comic-types"
                    link="show"
                  >
                    <SimpleShowLayout>
                      <TextField source="name" label="Name" />
                      <TextField source="storePrice" label="Store Price" />
                      <TextField source="isEcl" label="Is ECL" />
                      <TextField source="eclPrice" label="ECL Price" />
                      <TextField
                        source="eclUpgradePrice"
                        label="ECL Upgrade Price"
                      />
                    </SimpleShowLayout>
                  </ReferenceField>
                </SimpleShowLayout>
              </SingleFieldList>
            </ArrayField>
          </SimpleShowLayout>
        </Grid2>
      </Grid2>
    </Show>
  )
}
