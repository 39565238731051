const isWaitlistV2Enabled = () => {
  let enabled = false
  try {
    const featureFlagsJson = localStorage.getItem('featureFlags')
    if (featureFlagsJson) {
      const featureFlags = JSON.parse(featureFlagsJson)
      const waitlistV2 = featureFlags.find((flag) => flag.name === 'waitlistV2')

      if (waitlistV2 && waitlistV2.enabled) {
        enabled = true
      }
    }
  } catch {
    console.log('Error while parsing waitlistV2 feature flag')
  }

  return enabled
}

export default isWaitlistV2Enabled
