export const roles = {
  superadmin: [{ action: '*', resource: '*' }],
  admin: [
    { action: '*', resource: '*' },
    { type: 'deny', action: 'create', resource: 'users' },
    { type: 'deny', action: '*', resource: 'bulk' },
    { type: 'deny', action: 'assign_role', resource: 'users' },
    { type: 'deny', action: 'assign_gems', resource: 'users' },
    { type: 'deny', action: 'transfer_gems', resource: 'users' },
    { type: 'deny', action: 'transfer_collectibles', resource: '*' },
    { type: 'deny', action: 'transfer_comics', resource: '*' },
    { type: 'deny', action: '*', resource: 'promos' },
    { type: 'deny', action: 'edit', resource: 'payout-methods' },
    { type: 'deny', action: '*', resource: 'refund-payments' },
    { type: 'deny', action: '*', resource: 'payments/payout-nium-rfi' },
    { type: 'deny', action: 'airdrop_collectibles', resource: '*' },
    { type: 'deny', action: 'burn', resource: '*' },
    {
      type: 'deny',
      action: '*',
      resource: 'master-collector/transaction/create-reward',
    },
  ],
  content_creator: [
    { action: '*', resource: '*' },
    { type: 'deny', action: '*', resource: 'users' },
    { type: 'deny', action: '*', resource: 'bulk' },
    { type: 'deny', action: 'delete', resource: '*' },
    { type: 'deny', action: 'transfer_comics', resource: '*' },
    { action: 'delete_override', resource: 'media' },
    { type: 'deny', action: 'list', resource: 'collectibles' },
    { type: 'deny', action: 'list', resource: 'market-listings' },
    { type: 'deny', action: 'list', resource: 'store-auctions' },
    { type: 'deny', action: 'list', resource: 'transactions' },
    { type: 'deny', action: 'list', resource: 'login-background' },
    { type: 'deny', action: '*', resource: 'showrooms' },
    { type: 'deny', action: '*', resource: 'refund-payments' },
    { type: 'deny', action: '*', resource: 'feed' },
    { type: 'deny', action: '*', resource: 'comments' },
    { type: 'deny', action: 'edit', resource: 'auctions' },
    { type: 'deny', action: '*', resource: 'promos' },
    { type: 'deny', action: '*', resource: 'payout-methods' },
    { type: 'deny', action: '*', resource: 'payout-profiles' },
    { type: 'deny', action: '*', resource: 'payments/payouts' },
    { type: 'deny', action: '*', resource: 'payments/payout-nium-rfi' },
    { type: 'deny', action: 'airdrop_collectibles', resource: '*' },
    { type: 'deny', action: 'burn', resource: '*' },
    { type: 'deny', action: 'cancel', resource: '*' },
    {
      type: 'deny',
      action: '*',
      resource: 'master-collector/transaction/create-reward',
    },
  ],
  customer_support: [
    { action: ['list', 'show', 'edit'], resource: 'users' },
    { action: ['list', 'show'], resource: 'reward/rules' },
    { action: ['list'], resource: 'master-collector/user-summary' },
    { action: ['list'], resource: 'master-collector/transaction' },
    { action: ['list'], resource: 'master-collector/levels' },
    { action: ['list'], resource: 'master-collector/ranks' },
    { action: ['list', 'show'], resource: 'collectibles' },
    { type: 'deny', action: '*', resource: 'refund-payments' },
    { action: ['list', 'show'], resource: 'comics' },
    { action: ['list', 'show', 'edit'], resource: 'market-listings' },
    { action: ['list', 'show', 'edit'], resource: 'store-auctions' },
    { action: ['list', 'show', 'edit'], resource: 'store-auction-bids' },
    { action: ['list', 'show'], resource: 'transactions' },
    { action: ['list', 'show'], resource: 'media' },
    { action: ['list', 'show', 'edit'], resource: 'payout-profiles' },
    { action: ['list', 'show'], resource: 'kyc' },
    { action: ['list'], resource: 'user-gem-balance-log' },
    { action: '*', resource: 'feed' },
    { action: '*', resource: 'comments' },
    { action: '*', resource: 'account-merge' },
    { action: ['list', 'show'], resource: 'notification' },
    { action: ['list', 'show'], resource: 'auctions' },
    { action: ['list', 'show', 'edit'], resource: 'payout-profiles' },
    { action: ['list', 'show', 'cancel'], resource: 'payments/payouts' },
    { action: '*', resource: 'notes' },
    { type: 'deny', action: '*', resource: 'payout-methods' },
    { type: 'deny', action: '*', resource: 'payments/payout-nium-rfi' },
    { type: 'deny', action: 'burn', resource: '*' },
    { type: 'deny', action: '*', resource: 'marketing-window' },
  ],
  user: [],
  veveverse_client: [],
  veve_partner: [],
}
