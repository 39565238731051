import React from 'react'
import {
  Datagrid,
  DateField,
  Filter,
  List,
  TextField,
  ChipField,
  TextInput,
  SelectArrayInput,
} from 'react-admin'
import { defaultPageLimit } from '../../config/defaultPageLimit'

const ComicFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User Id" source="userId" />
    <TextInput label="User Email" source="email" />
    <SelectArrayInput
      source="statuses"
      choices={[
        { id: 'pending', name: 'Pending' },
        { id: 'pending_approval', name: 'Pending Approval' },
        { id: 'approved', name: 'Approved' },
        { id: 'rejected', name: 'Rejected' },
        { id: 'completed', name: 'Completed' },
        { id: 'failed', name: 'Failed' },
      ]}
      label="Statues"
    />
  </Filter>
)

export const ComicRefundList = (props) => (
  <List
    title="Comic Refunds"
    perPage={defaultPageLimit}
    filterDefaultValues={{ statuses: ['pending_approval'] }}
    filters={<ComicFilter />}
    {...props}
    style={{ 'padding-top': '20px' }}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <TextField source="user.id" label="User Id" sortable={false} />
      <TextField source="user.email" label="User Email" sortable={false} />
      <TextField source="cartId" label="Cart Id" sortable={false} />
      <TextField source="amountUsd" label="Refund Amount" sortable={false} />
      <ChipField source="status" sortable={false} />
      <DateField dateSource="createdAt" sortable={false} />
      <DateField dateSource="updatedAt" sortable={false} />
    </Datagrid>
  </List>
)
