import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import OtpInput from 'react-otp-input'

const TwoFactorAuthenticationDialog = (props) => {
  const [open, setTotpOpen] = React.useState(
    props.isTwoFactorAuthenticationPending || false
  )
  const [token, setToken] = React.useState(``)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  window.addEventListener('displayOtpModal', function (e) {
    setTotpOpen(true)
  })
  window.addEventListener('closeOtpModal', function (e) {
    handleClose()
  })

  window.addEventListener('wrongCredentials', function (e) {
    setLoading(false)
    setError(`Wrong credentials`)
  })

  const handleClose = () => {
    setTotpOpen(false)
    setLoading(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    window.dispatchEvent(
      new CustomEvent('otpEntered', {
        detail: {
          token,
        },
      })
    )
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">OTP</DialogTitle>
        <DialogContent>
          <OtpInput
            value={token}
            onChange={setToken}
            numInputs={6}
            separator={<span>-</span>}
          />
        </DialogContent>
        <DialogContent>
          <span style={{ color: 'red' }}>{error}</span>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="secondary"
            variant="contained"
            disabled={loading}
          >
            {loading && <CircularProgress size={18} thickness={2} />}
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { TwoFactorAuthenticationDialog }
