import React from 'react'
import { Link, useCreatePath, useRecordContext } from 'react-admin'
import { Chip } from '@mui/material'

const UserLink = (props) => {
  const { source, emptyText, userIdField, type } = props
  const record = useRecordContext(props)
  const id = record[userIdField || 'id']
  const user = record[source] || {
    id,
    username: emptyText || id,
  }
  const showEmail = !!props.showEmail ?? false
  const userLabel = (showEmail ? user.email : user.username) || emptyText
  const createPath = useCreatePath()
  const userShowPage = createPath({
    resource: '/users',
    id: user.id,
    type: 'show',
  })

  switch (type) {
    case 'chip':
      return (
        <Chip
          label={userLabel}
          component="a"
          href={`#${userShowPage}`}
          clickable
        />
      )
    default:
      return (
        <Link to={userShowPage} onClick={(e) => e.stopPropagation()}>
          {userLabel}
        </Link>
      )
  }
}

UserLink.defaultProps = {
  addLabel: true,
}

export { UserLink }
