import { LoginForm } from 'ra-ui-materialui'
import React from 'react'
import { Login } from 'react-admin'
import { ForgotPasswordDialog } from './utils/forgotPassword'
import { TwoFactorAuthenticationDialog } from '../../utils/twoFactorAuthentication'

const MyLogin = (props) => {
  return (
    <Login
      sx={{
        justifyContent: 'center',
        backgroundImage:
          'linear-gradient(to bottom,#1d1d1d, #3d3d3d, #2c2c2c);',
        '& .MuiPaper-root': {
          background: 'transparent',
        },
        '& .MuiFormControl-root': {
          width: '100%',
        },
        '& .RaLogin-avatar': {
          display: 'none',
        },
        '& .RaLogin-card': {
          marginTop: 0,
        },
      }}
    >
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img
          src="/icons/veve-logo.png"
          alt="veve logo"
          style={{ width: '12rem' }}
        />
      </div>
      <LoginForm />
      <ForgotPasswordDialog {...props} />
      <TwoFactorAuthenticationDialog {...props} />
    </Login>
  )
}

export default MyLogin
