import React from 'react'
import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin'
import ImgField from '../../utils/imageField'
import DateField from '../../utils/dateField'
import { Grid2 } from '@mui/material'
import { cuDates } from '../../theme/globalStyles'

const ComicPagesViewTitle = () => {
  const record = useRecordContext()
  return <span>View Comic Pages {`"${record?.id}"`}</span>
}

export const ComicPagesView = (props) => {
  const record = useRecordContext(props)
  if (record && record?.artists) {
    const artistIds = record?.artists.map((c) => c.id) || []
    record['artistIds'] = artistIds
  }

  return (
    <Show title={<ComicPagesViewTitle />} {...props}>
      <Grid2 sx={cuDates}>
        <DateField dateSource="createdAt" />
        <br />
        <DateField dateSource="updatedAt" />
      </Grid2>
      <Grid2>
        <SimpleShowLayout>
          <TextField label="Id" source="id" />
          <TextField source="comicType.name" label="Comic Type Name" />
          <NumberField source="pageNumber" />
          <ImgField source="url" label="" sortable={false} />
        </SimpleShowLayout>
      </Grid2>
    </Show>
  )
}
