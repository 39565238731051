import { defaultHttpHeaders } from '../config/defaultHeaders'
import { fetchUtils } from 'react-admin'

export const BaseURL = `${process.env.REACT_APP_API_BASE_URL}/admin`
export const httpClient = (url, options = {}) => {
  if (url.search('login-background') !== -1) {
    url = url.replace('login-background', 'media')
  }
  if (url.search('notes') !== -1) {
    url = url.replace('notes', 'users/notes')
  }

  options.headers = new Headers({
    ...defaultHttpHeaders,
    ...options.headers,
  })

  options.credentials = 'include'
  return fetchUtils.fetchJson(url, options)
}
