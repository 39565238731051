import { List } from '@react-admin/ra-rbac'
import React from 'react'
import {
  DeleteButton,
  Datagrid,
  DateField,
  Filter,
  TextField,
  TextInput,
  FunctionField,
} from 'react-admin'
import { defaultPageLimit } from '../../config/defaultPageLimit'

const FilterForm = (props) => (
  <Filter {...props}>
    <TextInput
      label="Convertible Collectible Type ID"
      source="convertCollectibleTypeId"
    />
    <TextInput
      label="Redeemed On Collectible Type ID"
      source="fromCollectibleTypeId"
    />
    <TextInput
      label="Reward Collectible Type ID"
      source="toCollectibleTypeId"
    />
  </Filter>
)

const rowIndexing = (record, index) => {
  record._id = index + 1
}

export const CollectibleTypeConvertMappingList = (props) => (
  <List
    title="Redemption Rewards"
    filters={<FilterForm />}
    perPage={defaultPageLimit}
    exporter={false}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid rowClick="show" rowSx={rowIndexing} bulkActionButtons={false}>
      <TextField source="_id" label="#" sortable={false} />
      <FunctionField
        label="Convertible Collectible Type"
        render={(record) => (
          <>
            [{record?.convertCollectibleType?.rarity.toUpperCase()}]{' '}
            {record?.convertCollectibleType?.name}
            <br />
            {record?.convertCollectibleTypeId}
          </>
        )}
      />
      <FunctionField
        label="Redeemed On Collectible Type"
        render={(record) => (
          <>
            [{record?.fromCollectibleType?.rarity.toUpperCase()}]{' '}
            {record?.fromCollectibleType?.name}
            <br />
            {record?.fromCollectibleTypeId}
          </>
        )}
      />
      <FunctionField
        label="Reward Collectible Type"
        render={(record) => (
          <>
            [{record?.toCollectibleType?.rarity.toUpperCase()}]{' '}
            {record?.toCollectibleType?.name}
            <br />
            {record?.toCollectibleTypeId}
          </>
        )}
      />
      <DateField dateSource="createdAt" sortable={true} />
      <DateField dateSource="updatedAt" sortable={false} />
      <DeleteButton label="Delete" />
    </Datagrid>
  </List>
)
