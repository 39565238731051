import * as React from 'react'
import { AppLocationContext } from '@react-admin/ra-navigation'
import { Layout } from 'react-admin'
import CustomAppBar from './customAppBar'
import { Menu } from './menu'

const CustomLayout = (props) => (
  <AppLocationContext>
    <Layout {...props} appBar={CustomAppBar} menu={Menu} />
  </AppLocationContext>
)

export default CustomLayout
