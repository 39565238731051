import { extendedRestProvider } from '../../utils/extendedRestProvider'
import { BaseURL, httpClient } from '../../utils/httpClient'
import _ from 'lodash'

export const payoutRfiDataProvider = {
  ...extendedRestProvider(BaseURL, httpClient, 'Content-Range', 'id'),
  update: (resource, params) => {
    const identificationDocuments = Object.keys(params.data)
      .filter((dataKey) => /identificationDocument/.test(dataKey))
      .map((key) => ({
        fieldName: key,
        files: params.data[key],
      }))

    if (_.isEmpty(identificationDocuments)) {
      return httpClient(`${BaseURL}/${resource}/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }).then(() => ({ data: params }))
    }

    return Promise.all(
      identificationDocuments.map(async (identificationDocument) => {
        return {
          fieldName: identificationDocument.fieldName,
          files: await Promise.all(
            identificationDocument.files.map(async (file) =>
              convertFileToBase64(file)
            )
          ),
        }
      })
    ).then(
      (transformedNewFiles) => {
        // Update params
        const body = {
          ...params.data,
        }
        transformedNewFiles.forEach((transformedNewFile) => {
          body[transformedNewFile.fieldName] = transformedNewFile.files
        })

        return httpClient(`${BaseURL}/${resource}/${params.id}`, {
          method: 'PUT',
          body: JSON.stringify(body),
        }).then(() => ({ data: params.data }))
      } // Prevent hasOwnProperty and id field checking of ReactAdmin
    )
  },
}

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve({
        fileName: file.title,
        fileType: file.title.split('.').pop(), // Get file extension
        document: reader.result
          ? reader.result.split(',').pop()
          : reader.result, // Remove data:*/*;base64,
      })
    }
    reader.onerror = reject

    reader.readAsDataURL(file.rawFile)
  })
}
