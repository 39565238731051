import { SplitFactoryProvider } from '@splitsoftware/splitio-react'

export function SplitIoProvider({ children }) {
  return (
    <SplitFactoryProvider
      config={{
        core: {
          authorizationKey: process.env.REACT_APP_SPLIT_API_KEY,
          key: 'dev',
        },
        debug: true,
      }}
    >
      {children}
    </SplitFactoryProvider>
  )
}
